"use strict";
//Models
import FiltersModel from '../_filters/model.js';
import Swiper, { Navigation , Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// update the query string based on the selected filters
const updateQueryString = FiltersModel.updateQueryString;

export default class ListView {

    static done = false;
    static loading_new_ads = false;
    static infinity_scroll_current_page = 1;

    // Listing and map view page
    static createSearchSuggestions(text , params) {
        const $suggestions = $('.suggestions-container ul');
        $suggestions.html('');
    
        let rooms = [];
        if(params.has('rooms')){
            let roomsParam = params.get('rooms');
            rooms = roomsParam.split(',');
        }
    
        for(let i = 1; i <= 5; i++){
            if(!rooms.includes(i.toString())){
                $suggestions.append(`
                    <li class="suggestion">${i} ${i === 1 ? 'Pièce' : 'Pièces'} ${text} </li>
                `);
            }
        }
        
        $('.suggestion').each(function(){
            const $this = $(this);
            const value = $this.text().split(' ')[0];
            $this.data('rooms', value);
        });
    }
    
    //Listing page
    static createFilterBubble(params) {
    
        const $filtersBubbleContainer = $(".filters-bubble");
        let appliedFilters = {};
        $filtersBubbleContainer.html('');
    
        /*
        text: filter text
        value : filter value,
        ref : filter dropdown id,
        inputName : input name in the form for the more-filter dropdown
         */
        if(params.has('bedrooms')){
            let bedrooms = params.get('bedrooms');
            appliedFilters.bedrooms = {
                text: bedrooms + ' chambre(s)',
                value: bedrooms,
                ref: 'more-filter-dropdown',
                inputName: 'chambers_number[]'
            };
        }
    
        if(params.has('keywords[]')){
            let keywords = params.getAll('keywords[]');
            appliedFilters.keywords = {
                text: keywords,
                value: keywords,
                ref: 'more-filter-dropdown',
                inputName: 'keywords[]'
            };
        }
    
        if(params.has('facility[]')){
            let facility = params.getAll('facility[]');
            appliedFilters.facility = {
                text: facility,
                value: facility,
                ref: 'more-filter-dropdown',
                inputName: 'facility[]'
            };
        }
        if(params.has('energy_class')){
            let energyClass = params.get('energy_class');
            appliedFilters.energyClass = {
                text: energyClass + ' Performance énergétique(s)',
                value: energyClass,
                ref: 'more-filter-dropdown',
                inputName: 'energy_class[]'
            };
        }
    
        if(params.has('listed_by')){
            let listedBy = params.get('listed_by');
            appliedFilters.listedBy = {
                text: listedBy,
                value: listedBy,
                ref: 'more-filter-dropdown',
                inputName: 'listed_by'
            };
        }
    
        if(params.has('is_first_owner')){
            appliedFilters.isFirstOwner = {
                text: 'Premier propriétaire',
                value: "1",
                ref: 'more-filter-dropdown',
                inputName: 'is_first_owner'
            };
        }
    
        if(params.has('rooms')){
            let rooms = params.get('rooms');
            appliedFilters.rooms = {
                text: rooms + ' pièce(s)',
                ref: 'pieces-dropdown',
            };
        }
    
        if(params.has('price_min') || params.has('price_max')){
            const price = $('#budget-input').text();
            appliedFilters.price = {
                text: price,
                ref: 'budget-dropdown',
            };
        }
    
        if(params.has('space_min') || params.has('space_max')){
            const space = $('#area-input').text();
            appliedFilters.space = {
                text: space,
                ref: 'area-dropdown',
            };
        }
    
        Object.values(appliedFilters)
        .forEach(filter => {
            if(filter.ref === 'more-filter-dropdown'){
                if(Array.isArray(filter.value)){
                    filter.value.forEach(value => {
                        $filtersBubbleContainer.append(`
                            <div data-ref="${filter.ref}" data-name="${filter.inputName}" data-value="${value}" class="filter-bubble bg-[#F7F7F7] px-4 py-1 rounded-full hover:cursor-pointer flex gap-4">
                                ${value}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </div>
                        `);
                    });
                }else{
                    $filtersBubbleContainer.append(`
                        <div data-ref="${filter.ref}" data-name="${filter.inputName}" data-value="${filter.value}" class="filter-bubble bg-[#F7F7F7] px-4 py-1 rounded-full hover:cursor-pointer flex gap-4">
                            ${filter.text}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    `);
                }
            }else{
                $filtersBubbleContainer.append(`
                    <div data-ref="${filter.ref}" class="filter-bubble bg-[#F7F7F7] px-4 py-1 rounded-full hover:cursor-pointer flex gap-4">
                        ${filter.text}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                `);
            }
        });
    
        if($filtersBubbleContainer.find('.filter-bubble').length !== 0){
            $filtersBubbleContainer.append(`
                <button type="reset" class="reset-bubble px-4 py-1 hover:cursor-pointer text-[#3485FF] order-last">
                    Tout effacer
                </button>
            `);
        }
    }
    
    // load ads in the search listing results page and the map
    static loadAds(url, page = 1 , clear, callback) {    
        //update the current query in the form and the url
        const $form = $('#search-page-filters');
        const actionUrl = $form.attr('action');
        let queryString = updateQueryString($form);
    
        $form.data('query', queryString);
        $('#show-map a').attr('href', actionUrl + '?' + queryString + '&map_view=true');
        window.history.pushState('', '', `?${queryString}`); // update the url
    
        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: url,
            type: 'POST',
            data: queryString + `&page=${page}` ,
            dataType: 'json',
            context: this,
            beforeSend: function () {
                if (clear) {
                    $('#realestate-container').html('');
                }
                $('#realestate-container').append(`
                <div role="status" class="loader mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
                    <svg aria-hidden="true" class="w-16 h-16 mr-2 text-gray-200 animate-spin fill-blue-brand" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                `);
            },
            success: function (response) {
                callback(response);
            }
        });
    }
    
    // home page map , listing and map view update
    static getAds(page = 1) {
        ListView.done = false;
        ListView.loading_new_ads = false;
        ListView.infinity_scroll_current_page = 1;
        const getAdsUrl = $('#listing-body').data('get-ads');
    
        ListView.loadAds(getAdsUrl, page, true, (response) => {
            const $criteria = $('#criteria').find('ul'); // alert criteria container
            const $alertForm = $('.alert-form');
            $criteria.html('');
    
            // update the alert criteria
            if (response.alert_criteria.length > 0) {
                $.each(response.alert_criteria, function (_, value) {
                    $criteria.append(`
                        <li class="inline-block mr-2 bg-[#F2F4F6] p-3 rounded-full mb-2 capitalize">${value}</li>
                    `)
                });
            }

            // update the alert form
            $alertForm.find('#ad_type_alert').val(response.filters.ad_type || 'sell');
            $alertForm.find('#realestate_type_alert').val(response.filters.realestate_type?.join(',') || '');
            $alertForm.find('#locations_alert').val(response.filters.locations?.join(',') || '');
            $alertForm.find('#rooms_alert').val(response.filters.rooms || '');
            $alertForm.find('#bedrooms_alert').val(response.filters.bedrooms || '');
            $alertForm.find('#price_min_alert').val(response.filters.price_min || '');
            $alertForm.find('#price_max_alert').val(response.filters.price_max || '');
            $alertForm.find('#space_min_alert').val(response.filters.space_min || '');
            $alertForm.find('#space_max_alert').val(response.filters.space_max || '');
            $alertForm.find('#facility_alert').val(response.filters.facility?.join(',') || '');
            $alertForm.find('#is_first_owner_alert').val(response.filters.is_first_owner || '0');
            $alertForm.find('#keywords_alert').val(response.filters.keywords?.join(',') || '');
            $alertForm.find('#energy_class_alert').val(response.filters.energy_class?.join(',') || '');
            $alertForm.find('#listed_by_alert').val(response.filters.listed_by || '');
    
            const params = new URLSearchParams(window.location.search);
    
            // update the search title
            $('#listing-title p').text(response.title);
    
            //create filter bubbles for the listing page
            ListView.createFilterBubble(params);
    
            //create dynamic search suggestions
            const suggestionText = response.title.replace(/\b(?!\(\+\d+\))\d+\b/, '').trim();
            ListView.createSearchSuggestions(suggestionText, params);
    
            const $realEstateContainer = $('#realestate-container');
            const noAdsMessage = `
                <div role="status" class="mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-blue-brand text-center">
                            <p class="fa-solid fa-4x fa-circle-exclamation"></p>
                            <p class="text-xl mt-1">
                                Aucune annonce ne correspond à vos critères pour le moment
                            </p>
                </div>
            `;
    
            if (response.html.list.length == 0) {
                $realEstateContainer
                    .html('')
                    .append(noAdsMessage);
                return;
            }
            
            $realEstateContainer.html('');
    
            if($('#list-view-btn').hasClass('active-btn')){
                $realEstateContainer.append(`
                    <div class="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-view gap-2 hidden"></div>
                    <div class="list-view"></div>
                `);
            }else{
                $realEstateContainer.append(`
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-view gap-2"></div>
                    <div class="list-view hidden"></div>
                `);
            }
    
            const $gridView = $realEstateContainer.find('.grid-view');
            const $listView = $realEstateContainer.find('.list-view');
    
            $gridView.append(response.html.grid);
            $listView.append(response.html.list);


            // Initialize Swiper for each card
            $('.swiper-container-list').each(function (_, element) {
                const swiperId = $(element).attr('id'); // Get the unique swiper ID        

                // Initialize a new Swiper instance for each container
                new Swiper('#' + swiperId, {
                    loop: true,
                    slidesPerView: 1,
                    modules: [ Navigation, Pagination ],
                    pagination: {
                        el: '#' + swiperId + ' .swiper-pagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    navigation: {
                        nextEl: '#ad-' + swiperId + ' .custom-button-next',
                        prevEl: '#ad-' + swiperId + ' .custom-button-prev',
                    },
                    on: {
                        navigationNext: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                        navigationPrev: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                    },
                });
            });
            $('.swiper-container-grid').each(function (_, element) {
                const swiperId = $(element).attr('id'); // Get the unique swiper ID        

                // Initialize a new Swiper instance for each container
                new Swiper('#' + swiperId, {
                    loop: true,
                    slidesPerView: 1,
                    modules: [ Navigation, Pagination ],
                    pagination: {
                        el: '#' + swiperId + ' .swiper-pagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    navigation: {
                        nextEl: '#ad-' + swiperId + ' .custom-button-next',
                        prevEl: '#ad-' + swiperId + ' .custom-button-prev',
                    },
                    on: {
                        navigationNext: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                        navigationPrev: function () {
                            $('#current-slide').html(this.realIndex + 1);
                        },
                    },
                });
            });
            
        });
    }
}